import { useContext, useState } from 'react';
import { Box, Checkbox, Link, Text } from '@bitrise/bitkit';

import PublicPage from '@/components/page/PublicPage';
import PageContent from '@/components/page/PageContent/PageContent';
import PageTitle, { PageTitleHeading, PageTitleSubheading } from '@/components/page/PageTitle/PageTitle';
import NotificationSlot from '@/components/NotificationSlot/NotificationSlot';
import BackButton from '@/components/buttons/BackButton/BackButton';
import LoginButtonGroup from '@/components/buttons/LoginButtonGroup/LoginButtonGroup';
import LoginCard from '@/components/LoginCard/LoginCard';
import StaticHeader from '@/components/page/PageHeader/StaticHeader';
import { GlobalPropsContext } from '@/components/page/GlobalPropsContext';
import SignInForm from './components/SignInForm';
import useSignInFlashMessage from './hooks/useSignInFlashMessage';
import SSOEmailForm from './SSOEmailForm';

const SignInPage = () => {
  const [formState, setFormState] = useState<'selection' | 'email' | 'sso'>('selection');
  const [rememberMe, setRememberMe] = useState(false);
  const rememberMePart = typeof rememberMe === 'boolean' ? `?remember_me=${rememberMe}` : '';

  useSignInFlashMessage();

  const { featureFlags } = useContext(GlobalPropsContext);
  const ssoButtonProps = featureFlags.user.emailSamlSignIn
    ? {
        onClick: () => setFormState('sso'),
      }
    : {
        as: 'a' as const,
        href: `/users/sign-in/saml${rememberMePart}`,
      };

  return (
    <PublicPage
      bgGradient="linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, purple.95 100%), neutral.100"
      h="calc(100vh)"
    >
      <StaticHeader>
        <Link colorScheme="purple" href="/users/sign_up">
          Sign up &rarr;
        </Link>
      </StaticHeader>
      <PageTitle textAlign="center">
        <PageTitleHeading size="6">Log in to your account</PageTitleHeading>
        <PageTitleSubheading size="3">Welcome back!</PageTitleSubheading>
      </PageTitle>
      <PageContent paddingTop="0">
        <NotificationSlot />
        {formState !== 'sso' && (
          <LoginCard>
            {formState === 'email' && (
              <Box display="flex" flexDirection="column" gap="24" maxWidth="368">
                <BackButton onBack={() => setFormState('selection')} text="Log in another way" />
                <Text as="h2" fontWeight="bold" size="4">
                  Log in with Email
                </Text>
                <SignInForm rememberMe={rememberMe} />
              </Box>
            )}
            {formState === 'selection' && (
              <LoginButtonGroup
                onEmailSelect={() => setFormState('email')}
                ssoButtonProps={ssoButtonProps}
                signUpMode={false}
                rememberMe={rememberMe}
              />
            )}
          </LoginCard>
        )}
        {formState === 'sso' && (
          <SSOEmailForm
            backLabel="Sign in without SSO"
            cardProps={{ gap: '24' }}
            header={
              <div>
                <Text textStyle="heading/h1">Sign in with SSO</Text>
                <Text textStyle="body/md/regular" color="text/secondary">
                  Please sign in using your SSO credentials.
                </Text>
              </div>
            }
            onBack={() => setFormState('selection')}
          />
        )}
        {formState === 'selection' && (
          <Link colorScheme="purple" href="/users/forgot-password" textAlign="center">
            Forgot your password?
          </Link>
        )}
        {formState === 'email' && (
          <Checkbox
            isChecked={rememberMe}
            onChange={(ev) => setRememberMe(ev.currentTarget.checked)}
            alignSelf="center"
          >
            Keep me logged in
          </Checkbox>
        )}
      </PageContent>
    </PublicPage>
  );
};

export default SignInPage;
