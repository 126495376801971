import { Box, Checkbox, CheckboxProps, Link, Text, forwardRef } from '@bitrise/bitkit';

const TermsOfServiceAndPrivacyPolicy = forwardRef<CheckboxProps, typeof Checkbox>(
  (props, ref): JSX.Element => (
    <Box>
      <Checkbox marginBottom="12" inputRef={ref} {...props}>
        <span>
          I agree to the Bitrise{' '}
          <Link colorScheme="purple" isExternal href="https://go.bitrise.io/terms-of-service">
            Terms of Service
          </Link>
          .
        </span>
      </Checkbox>
      <Text textStyle="body/sm/regular" color="text/secondary">
        Bitrise may use your data provided above to contact you via email about Bitrise news, events, webinars,
        whitepapers or send you other direct marketing communications. You may object at any time by unsubscribing in
        emails or visiting the{' '}
        <Link colorScheme="purple" isExternal href="https://bitrise.io/communication-preference-center">
          Preference Center
        </Link>
        . By submitting your contact details, you acknowledge that you reviewed and understood our{' '}
        <Link colorScheme="purple" isExternal href="https://go.bitrise.io/privacy-policy">
          Privacy Policy
        </Link>
        .
      </Text>
    </Box>
  ),
);

export default TermsOfServiceAndPrivacyPolicy;
